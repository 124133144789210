@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@import 'variables';
@import './components/jumbotron';
@import './components/tabs';
@import './components/loader';
@import './components/searchbar';
@import './components/card-product';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $body-bg;
  font-family: $font-family;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: bold;
}

.App {
  $c: '.App';

  &.is-loading {
    display: flex;
    flex-direction: column;
    height: 100vh;

    #{$c}__body {
      position: relative;
      flex: 1;
    }
  }

  &__filter {
    position: sticky;
    top: 0;
    z-index: 20;
  }
}

.text-center {
  text-align: center;
}

.m-2 {
  margin: 2rem;
}
