@import '../variables';

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: $body-bg;
  display: none;

  &.is-visible {
    display: flex;
  }

  &__inner {
    margin: 50px auto;
    text-indent: -9999em;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ffffff;
    background: linear-gradient(to right, $color-primary 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: spin 1.4s infinite linear;
    transform: translateZ(0);

    &:before {
      width: 50%;
      height: 50%;
      background: $color-primary;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }

    &:after {
      background: $body-bg;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
