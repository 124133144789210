$body-bg: #eee;
$font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-size-small: 14px;
$font-size-base: 16px;
$font-size-heading: 22px;
$gutter: 20px;

$color-text: #000;
$color-primary: #ac2f1e;

$tabs-bg: #fff;
$searchbar-bg: #fff;
$searchbar-input-bg: $body-bg;

$card-product-bg: #fff;
