@import '../variables';

.Searchbar {
  position: relative;
  background-color: $searchbar-bg;
  padding: calc($gutter / 2);

  &__input {
    background-color: $searchbar-input-bg;
    font-family: inherit;
    font-size: $font-size-base;
    width: 100%;
    border: 0;
    margin: 0;
    border: 0;
    outline: none;
    padding: 8px 16px;
    border-radius: 12px;
  }
}
