@import '../variables';

.Jumbotron {
  position: relative;
  background-color: $color-primary;

  &__image {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
    }
  }
}
