@import '../variables';

.CardProduct {
  $c: '.CardProduct';

  position: relative;
  margin: calc($gutter / 2);
  padding: 16px;
  background-color: $card-product-bg;
  border-radius: 8px;
  cursor: pointer;

  &__translateDisclaimer {
    font-size: 12px;
    font-style: italic;
    text-align: center;
  }

  &__pretitle {
    margin-top: 0;
    margin-bottom: .5rem;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: $font-size-heading;
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '▼';
      font-size: 80%;
    }
  }

  &__description {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: $font-size-base;
  }

  &__accordionBody {
    display: none;
    padding-top: 1rem;

    h4 {
      margin-top: 0;
      font-size: .85rem;
    }

    p,
    > *:last-child {
      margin-top: 0;
    }

    br {
      display: none;
    }

    table tr td {
      border: 1px solid #000;
    }
  }

  &.is-toggle {
    #{$c}__title:after {
      content: '▲';
    }

    #{$c}__accordionBody {
      display: block;
    }
  }
}
