@import '../variables';

.Tabs {
  position: relative;
  background-color: $tabs-bg;
  overflow-x: auto;
  overflow-y: hidden;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;

    li {
      white-space: nowrap;
    }
  }

  button {
    text-transform: uppercase;
    color: $color-primary;
    border: 0;
    padding: calc($gutter / 1.5);
    padding-bottom: calc(calc($gutter / 1.5) - 2px);
    margin: 0;
    outline: none;
    appearance: none;
    background-color: transparent;
    font-weight: bold;
    border-bottom: 5px solid transparent;

    &.is-active {
      border-bottom-color: $color-primary;
    }
  }
}
